import dynamic from 'next/dynamic';
import * as FLAGS from '@/constants/flags';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {
  useAutoOpenChatBox,
  useCurrentSlug,
  useRenderInIFrame,
  useShowEnhancedSection,
} from '@/hooks';
import {useSectionsStatus} from '@/hooks/showSections/useSectionsStatus';

const Hero = dynamic(() => import('./Hero'));
const LabelSection = dynamic(() => import('../LabelSection'));

const FASections = dynamic(
  () => import('@/modules/ProductDetailPage/FASections'),
);
const PatientEducation = dynamic(
  () => import('@/modules/ProductDetailPageNew/PatientEducation'),
);
const PeopleAlsoAsk = dynamic(() => import('../PeopleAlsoAsk'));
const PriorAuthorizationSection = dynamic(
  () => import('@/modules/ProductDetailPage/PriorAuthorizationSection'),
);
const RequestSample = dynamic(
  () => import('@/modules/ProductDetailPageNew/RequestSamples/RequestSample'),
);
const PubMedNews = dynamic(
  () => import('@/modules/ProductDetailPageNew/PubMedNews'),
);
const CoverageRestrictions = dynamic(
  () => import('@/modules/ProductDetailPageNew/CoverageRestrictions'),
);
const Ai = dynamic(() => import('./AI'), {ssr: false});

const Sections = () => {
  const {setId} = useCurrentSlug();
  const pdpIsEmbedded = useRenderInIFrame();

  const {
    showPE,
    showPA,
    showFAQS,
    showPubMed,
    showLabel,
    showCoverageOnPdp,
    isCoverageHighlited,
  } = useSectionsStatus();

  const showRS =
    useShowEnhancedSection({
      sectionId: SECTION_IDS.requestSamples,
      flagName: FLAGS.REQUEST_SAMPLES_SECTION,
      checkAvailableResources: true,
    }) && !pdpIsEmbedded;

  useAutoOpenChatBox();

  return (
    <div className='relative'>
      <Hero />
      {showCoverageOnPdp && !isCoverageHighlited ? (
        <CoverageRestrictions />
      ) : null}
      <Ai />
      <div className='divide-x-0 divide-y divide-solid divide-gray-200'>
        {showLabel ? <LabelSection /> : null}
        {showRS ? <RequestSample /> : null}
        {showPA ? <PriorAuthorizationSection /> : null}
        <FASections setId={setId} />
        {showPubMed ? <PubMedNews /> : null}
        {showPE ? <PatientEducation /> : null}
        {showFAQS ? <PeopleAlsoAsk /> : null}
      </div>
    </div>
  );
};

export default Sections;
