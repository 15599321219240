import clsx from 'clsx';
import dynamic from 'next/dynamic';
import DosingAndAdmin from '../DosingAndAdmin';
import {Section} from '@/components';
import * as FLAGS from '@/constants/flags';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context';
import {
  useCurrentSlug,
  useEnhanced,
  useRenderInIFrame,
  useShowFixedCTAMenu,
  useUser,
} from '@/hooks';
import {useSectionsStatus} from '@/hooks/showSections/useSectionsStatus';

const MostViewedResources = dynamic(() => import('../MostViewedResources'));
const Reps = dynamic(() => import('../Reps'));

const Hero = () => {
  const {setId} = useCurrentSlug();
  const {isEnhanced} = useEnhanced(setId);
  const pdpIsEmbedded = useRenderInIFrame();
  const showMVR = useFlag(FLAGS.MOST_VIEW_RESOURCES_SECTION) && isEnhanced;
  const {logged} = useUser();
  const showR = useFlag(FLAGS.REPS_SECTION) && logged && !pdpIsEmbedded;
  const showCTAFixedMenu = useShowFixedCTAMenu();
  const {showDosage} = useSectionsStatus();

  const showHero = !pdpIsEmbedded;

  return showHero ? (
    <Section
      id={SECTION_IDS.summary}
      className={clsx('relative', [showCTAFixedMenu ? 'mt-10' : '!py-0'])}>
      {showR ? <Reps /> : null}
      {showDosage ? <DosingAndAdmin /> : null}
      {showMVR ? <MostViewedResources /> : null}
    </Section>
  ) : null;
};

export default Hero;
