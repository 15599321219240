export {default as Home} from './HomePage';
export {default as Index} from './IndexPage';
export {default as ProductDetail, OTCProductDetail} from './ProductDetailPage';
export {default as ProductDetailNew} from './ProductDetailPageNew';
export {default as Forms} from './FormsPage';
export {default as IndicationPage} from './IndicationPage';
export {default as Features} from './SignupPage/Features';
export {default as CompareDrugsPage} from './CompareDrugsPage';
export {default as BrandInsuranceFormPage} from './BrandInsuranceFormPage';
export {default as PriorAuthorizationFormsByBrandPage} from './PriorAuthorizationFormsByBrand';
export {default as FinancialAssistance} from './ProductDetailPage/FinancialAssistance';
