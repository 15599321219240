'use client';
import {Modal, Text, Loader} from '@prescriberpoint/ui';
import dynamic from 'next/dynamic';
import {FC, useState, useEffect} from 'react';
import RepRegistryPanel from './RepRegistry/RepRegistryPanel';
import {REQUEST_SAMPLES_HOME, TERMS_AND_CONDITIONS} from '@/constants/flags';
import {useUserAgentContext, useFlag} from '@/context';
import {useRouter} from '@/hooks/useRouter';
import useUserData from '@/hooks/useUserData';
import {useCurrentPerson} from '@/queries/useCurrentPerson';

const Welcome = dynamic(() => import('./Welcome'), {
  ssr: false,
  loading: () => <Loader />,
});
const RepRegistry = dynamic(() => import('./RepRegistry'), {
  ssr: false,
});
const RequestSamples = dynamic(() => import('./RequestSamples'), {
  ssr: false,
});

const TermsAndConditions = dynamic(() => import('./TermsAndConditions/'), {
  ssr: false,
});

const Home: FC = () => {
  const router = useRouter();
  const termsAndConditionsEnabled = useFlag(TERMS_AND_CONDITIONS);
  const showRS = useFlag(REQUEST_SAMPLES_HOME);
  const {data: user} = useCurrentPerson();
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showPanel, setShowPanel] = useState(true);
  const {userData} = useUserData();

  useEffect(() => {
    if (userData.showRepQrInstructions) {
      setShowPanel(true);
    }
  }, [userData.showRepQrInstructions]);

  useEffect(() => {
    if (termsAndConditionsEnabled && user)
      setShowTermsAndConditions(user.shouldNeedAcceptTsCs);
  }, [user, termsAndConditionsEnabled]);

  const {source} = router.query;
  const {isMobileOrTablet} = useUserAgentContext();

  const [mobileModalOpen, setMobileModalOpen] = useState(
    (source === 'task' || source === 'case') && isMobileOrTablet,
  );

  const closeTermsAndConditions = () => {
    setShowTermsAndConditions(false);
  };

  return (
    <>
      <Welcome />
      <div className='w-full'>
        <div
          id='ppt-home-large-ad-container'
          className='max-w-[800px] md:mx-auto'>
          <div className='hidden' />
        </div>
      </div>
      {showRS ? (
        <RequestSamples />
      ) : (
        <RepRegistry
          showPanel={showPanel && !showTermsAndConditions}
          setShowPanel={setShowPanel}
        />
      )}
      <Modal
        closeModal={() => {}}
        isOpen={mobileModalOpen}
        showCloseButton={false}
        title='Log in on desktop to view'
        showConfirm={false}
        showCancel
        cancelText='Dismiss'
        onCancel={() => setMobileModalOpen(false)}
        className='px-4 py-6'>
        <Text>
          This feature is optimized for a desktop experience and currently
          unavailable on your smart phone.
        </Text>
      </Modal>
      <RepRegistryPanel
        showPanel={showPanel}
        handleClose={() => setShowPanel(false)}
      />
      {showTermsAndConditions && (
        <TermsAndConditions handleClose={closeTermsAndConditions} />
      )}
    </>
  );
};

export default Home;
