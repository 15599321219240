import dynamic from 'next/dynamic';
import LabelSection from '../LabelSection';
import Hero from './Hero';
import * as FLAGS from '@/constants/flags';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {
  useAutoOpenChatBox,
  useCurrentSlug,
  useRenderInIFrame,
  useShowEnhancedSection,
} from '@/hooks';
import {useSectionsStatus} from '@/hooks/showSections/useSectionsStatus';

const FASections = dynamic(() => import('../FASections'));
const PatientEducation = dynamic(() => import('../PatientEducation'));
const PeopleAlsoAsk = dynamic(() => import('../PeopleAlsoAsk'));
const PriorAuthorizationSection = dynamic(
  () => import('../PriorAuthorizationSection'),
);
const RequestSample = dynamic(() => import('../RequestSamples/RequestSample'));
const PubMedNews = dynamic(() => import('../PubMedNews'));
const CoverageRestrictions = dynamic(() => import('../CoverageRestrictions'));
const Ai = dynamic(() => import('./AI'), {ssr: false});

const Sections = () => {
  const {setId} = useCurrentSlug();
  const pdpIsEmbedded = useRenderInIFrame();

  const {showPE, showPA, showFAQS, showPubMed, showLabel, showCoverageOnPdp} =
    useSectionsStatus();

  const showRS =
    useShowEnhancedSection({
      sectionId: SECTION_IDS.requestSamples,
      flagName: FLAGS.REQUEST_SAMPLES_SECTION,
      checkAvailableResources: true,
    }) && !pdpIsEmbedded;

  useAutoOpenChatBox();

  return (
    <>
      <Hero />
      {showCoverageOnPdp ? <CoverageRestrictions /> : null}
      <Ai />
      {showLabel ? <LabelSection /> : null}
      {showRS ? <RequestSample /> : null}
      {showPA ? <PriorAuthorizationSection /> : null}
      <FASections setId={setId} />
      {showPubMed ? <PubMedNews /> : null}
      {showPE ? <PatientEducation /> : null}
      {showFAQS ? <PeopleAlsoAsk /> : null}
    </>
  );
};

export default Sections;
