import {replaceVariablesInStr} from '@prescriberpoint/ui';
import {FC, useMemo, useState} from 'react';
import Header from './Header';
import PayerFormCard from './PayerFormsCard';
import {PageContentLoader} from '@/components';
import {PRIOR_AUTH_FORMS_BY_PAYER_PAGE} from '@/data/priorAuthorizationFormsByPayerPage';
import {useCurrentDrug} from '@/hooks';
import {usePAFormsByBrand} from '@/queries';
import {formatISOtoString, getLastVerifiedDate} from '@/utils/date';

export interface IPriorAuthorizationFormsByBrandPageProps {}

const PriorAuthorizationFormsByBrandPage: FC<
  IPriorAuthorizationFormsByBrandPageProps
> = ({}) => {
  const {drugName, setId} = useCurrentDrug();
  const [sortBy, setSortBy] = useState('asc');
  const {data, isLoading} = usePAFormsByBrand(setId);

  const lastUpdated = useMemo(() => {
    const dates = data?.carriers?.[0]?.documents.map((e) => e.lastUpdatedDate);
    if (!dates) return '';
    const mostRecentDate = new Date(
      Math.max(...dates.map((date) => Date.parse(date))),
    );
    const verifiedDate = getLastVerifiedDate(mostRecentDate.toISOString());
    return formatISOtoString(verifiedDate);
  }, [data]);

  const sortedForms = useMemo(
    () =>
      sortBy === 'asc'
        ? data?.carriers?.sort((a, b) => a.name.localeCompare(b.name))
        : data?.carriers?.sort((a, b) => b.name.localeCompare(a.name)),
    [data?.carriers, sortBy],
  );

  if (isLoading) {
    return <PageContentLoader />;
  }

  return (
    <div className='relative mx-auto max-w-[1440px] animate-fadeIn bg-white px-6 md:px-0'>
      <Header
        title={replaceVariablesInStr(PRIOR_AUTH_FORMS_BY_PAYER_PAGE.title, {
          brandName: drugName,
        })}
        description={PRIOR_AUTH_FORMS_BY_PAYER_PAGE.description}
        showSortSelect={!!sortedForms && sortedForms?.length > 1}
        setSortBy={(value?: string) => setSortBy(value ?? 'asc')}
        sortBy={sortBy}
        lastUpdated={lastUpdated}
      />
      <div className='flex flex-col space-y-2'>
        {sortedForms?.map((form) => (
          <PayerFormCard key={form.id} payer={form} />
        ))}
      </div>
    </div>
  );
};

export default PriorAuthorizationFormsByBrandPage;
