/* eslint-disable @next/next/no-img-element */
import {Text, Icon, Button, Link, Loader} from '@prescriberpoint/ui';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';
import {FC} from 'react';
import DownloadButton from '../DownloadButton';
import {useUserAgentContext} from '@/context';
import {ResourceDto} from '@/models';
import {toSnakeCase} from '@/utils/string';

const MobileResourceItem = dynamic(() => import('./MobileResourceItem'), {
  loading: () => <Loader />,
});

export interface ResourceItemProps {
  resourceIdx: number;
  resource: ResourceDto;
  selected: boolean;
}

const ResourceItem: FC<ResourceItemProps> = ({
  resourceIdx,
  resource,
  selected,
}) => {
  const {isMobileOrTablet} = useUserAgentContext();

  const router = useRouter();
  const handleViewClick = () => {
    router.query.resourceId = `${resourceIdx}`;
    router.push(router);
  };

  return isMobileOrTablet ? (
    <MobileResourceItem
      resourceIdx={resourceIdx}
      resource={resource}
      selected={selected}
    />
  ) : (
    <div className='flex w-full rounded-md border border-solid border-neutral-light bg-white px-2 py-4'>
      <div
        className={clsx(
          'mr-4 flex h-auto items-center justify-center rounded-md bg-neutral-lighter',
          {'h-10 w-10 p-1': !selected},
          {'h-20 w-20 p-2': selected},
        )}>
        {resource.image ? (
          <img
            src={resource.image}
            alt={resource.title}
            className='h-full w-full bg-white object-contain'
          />
        ) : (
          <div className='flex h-full w-full items-center justify-center bg-white'>
            <Icon name='Insurance' className='h-6 w-6' />
          </div>
        )}
      </div>
      {selected ? (
        <div className='w-full'>
          <div className='mb-2 h-20'>
            <Text weight='semibold'>{resource.title}</Text>
            <div className='flex items-center'>
              <Text className='mr-1'>Source: </Text>
              <Link
                href={resource.source}
                className='truncate'
                label={resource.source}
              />
            </div>
          </div>
          <div className='flex w-full space-x-4'>
            <DownloadButton fileName={resource.title} fileUrl={resource.url} />
          </div>
        </div>
      ) : (
        <>
          <Text weight='semibold' className='block flex-1'>
            {resource.title}
          </Text>
          <Button
            id={`view_${toSnakeCase(resource.title)}`}
            onClick={handleViewClick}>
            View
          </Button>
        </>
      )}
    </div>
  );
};

export default ResourceItem;
