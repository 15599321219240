import {Link} from '@prescriberpoint/ui';
import clsx from 'clsx';
import DOMPurify from 'isomorphic-dompurify';
import {FC, useCallback, useMemo, useRef} from 'react';
import AnimateHeight from 'react-animate-height';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {SectionNew} from '@/components';
import {CEVENT_VIEW_MORE_BTN_CLICKED, DlvViewMoreBtnClicked} from '@/constants';
import {OTHER_SECTION_IDS} from '@/constants/sectionIDs';
import {DRUG_SUMMARIES} from '@/data/contentSummaries';
import {useCurrentSlug, useShowContentSummaries} from '@/hooks';
import {useSectionsStatus} from '@/hooks/showSections/useSectionsStatus';
import useViewMore from '@/hooks/useViewMore';
import {useLabel} from '@/queries';
import {customEvent, proccessSectionId} from '@/utils/gtm';
import {
  removeAllHTMLElementInStr,
  removeHeaderNumbersAndAnchors,
  formatDosageHTML,
  removeTableWidthAttribute,
} from '@/utils/string';

const DEFAULT_HEIGHT = 150;
interface ISectionContentProps {
  setId: string;
  sanitizedText: string;
}
const SectionContent: FC<ISectionContentProps> = ({setId, sanitizedText}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const {height, toggleHeight, showClampButton, textClamped} = useViewMore(
    containerRef,
    DEFAULT_HEIGHT,
  );
  const showContentSummaries = useShowContentSummaries(setId);

  const showGradient = textClamped && showClampButton;

  const adjustHeight = useCallback(
    (e: any) => {
      e.preventDefault();
      if (textClamped) {
        customEvent<DlvViewMoreBtnClicked>(CEVENT_VIEW_MORE_BTN_CLICKED, {
          resourceSection: proccessSectionId(
            OTHER_SECTION_IDS.dosingAndAdmiministration,
          ),
        });
      }
      toggleHeight();
    },
    [toggleHeight, textClamped],
  );

  return (
    <div
      className={clsx('relative flex flex-col space-y-4 rounded-lg bg-white', {
        'pb-4': showGradient,
      })}>
      <AnimateHeight height={height} duration={500}>
        {showContentSummaries ? (
          <div
            ref={containerRef}
            className='drug-label-table overflow-x-auto px-4 pt-4 text-neutral-primary [&>p>a]:!text-neutral-primary [&>table]:mb-4'>
            <Markdown remarkPlugins={[remarkGfm]}>
              {DRUG_SUMMARIES?.[setId]}
            </Markdown>
          </div>
        ) : (
          <div
            ref={containerRef}
            dangerouslySetInnerHTML={{
              __html: sanitizedText,
            }}
            className={clsx('drug-label-table overflow-x-auto px-4 pt-4', [
              'text-neutral-primary [&>div>:first-child]:mt-0',
              '[&>div>label>label>label]:inline-block [&>div>label>label>label]:pl-5',
            ])}
          />
        )}
      </AnimateHeight>
      <div
        className={clsx(
          'flex w-full items-end justify-end px-4 pb-4 transition-all',
          {
            'absolute bottom-0 h-full bg-gradient-to-b from-transparent to-white':
              showGradient,
          },
        )}>
        {showClampButton ? (
          <Link
            aria-expanded={!textClamped}
            onClick={adjustHeight}
            label={textClamped ? 'View more' : 'View less'}
            className='bg-white'
          />
        ) : null}
      </div>
    </div>
  );
};

interface IDosingAndAdmingProps {}

const DosingAndAdmin: FC<IDosingAndAdmingProps> = () => {
  const {setId} = useCurrentSlug();
  const {data: product} = useLabel(setId);
  const content = product?.label.highlights.dosageAndAdministration ?? '';
  const showContentSummaries = useShowContentSummaries(setId);

  const sanitizedText = useMemo(() => {
    let safeContent = removeHeaderNumbersAndAnchors(
      removeAllHTMLElementInStr(content, 'h1'),
    ).replace(/\s{9}/g, '');

    safeContent = removeTableWidthAttribute(safeContent);

    return DOMPurify.sanitize(formatDosageHTML(safeContent));
  }, [content]);

  return (
    <SectionNew
      id={OTHER_SECTION_IDS.dosingAndAdmiministration}
      data-testid={OTHER_SECTION_IDS.dosingAndAdmiministration}
      title={
        showContentSummaries ? 'Quick Reference' : 'Dosage & Administration'
      }>
      <SectionContent setId={setId} sanitizedText={sanitizedText} />
    </SectionNew>
  );
};

const DosingAndAdminWrapper: FC<IDosingAndAdmingProps> = () => {
  const {showDosage} = useSectionsStatus();
  return showDosage ? <DosingAndAdmin /> : null;
};

export default DosingAndAdminWrapper;
