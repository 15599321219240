import {Text} from '@prescriberpoint/ui';
import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';
import {FC, useEffect, useMemo} from 'react';
import Header from './Header';
import ResourceItem from './ResourceItem';
import {PageContentLoader} from '@/components';
import {useUserAgentContext} from '@/context';
import {useCurrentSlug} from '@/hooks';
import {usePAFormsByBrand} from '@/queries';
import {capitalize, getPdfUrlFromFilename} from '@/utils';
import {formatISOtoString, getLastVerifiedDate} from '@/utils/date';

export interface FormsProps {}

const FormViewer = dynamic(() => import('./FormViewer'));

const BrandInsuranceFormPage: FC<FormsProps> = () => {
  const router = useRouter();

  const {resourceId} = router.query;

  const {setId} = useCurrentSlug();

  useEffect(() => {
    if (!resourceId) {
      router.query.resourceId = '0';
      router.push(router);
    }
  }, [resourceId, router]);

  const {data, isFetching} = usePAFormsByBrand(
    setId,
    router.query.payerName as string,
  );
  const payerName = capitalize(data?.carriers?.[0]?.name as string);

  const {isMobileOrTablet} = useUserAgentContext();

  const currentResourceId: number = Number(resourceId ?? -1);

  const currentResource = useMemo(
    () => data?.carriers?.[0].documents?.[currentResourceId],
    [currentResourceId, data?.carriers],
  );

  const lastUpdated = useMemo(() => {
    const dates = data?.carriers[0].documents.map((e) => e.lastUpdatedDate);
    if (!dates) return '';
    const mostRecentDate = new Date(
      Math.max(...dates.map((date) => Date.parse(date))),
    );
    const verifiedDate = getLastVerifiedDate(mostRecentDate.toISOString());
    return formatISOtoString(verifiedDate);
  }, [data?.carriers]);

  if (isFetching) {
    return <PageContentLoader />;
  }

  return (
    <div className='relative mt-4 max-w-[1440px] px-6 md:mx-auto md:mb-4 md:mt-8 md:flex md:flex-col md:px-0'>
      <Header lastUpdated={lastUpdated} payerName={payerName} />
      <div className='mt-10 grid grid-cols-1 gap-4 md:grid-cols-3'>
        {!isMobileOrTablet && currentResource?.filename ? (
          <div className='col-span-3 md:col-span-2'>
            <FormViewer
              id={`form_${currentResource.filename}`}
              fileUrl={getPdfUrlFromFilename(currentResource?.filename)}
            />
          </div>
        ) : null}

        <div className='order-first col-span-3 flex flex-col space-y-4 md:order-last md:col-span-1'>
          {isMobileOrTablet ? (
            <div className='h-[350px] bg-neutral-light'>
              {currentResource?.filename && (
                <FormViewer
                  id={`form_${currentResource.filename}`}
                  fileUrl={getPdfUrlFromFilename(currentResource?.filename)}
                />
              )}
            </div>
          ) : null}

          <div className='flex flex-col space-y-2 rounded-lg bg-neutral-light px-2 py-4'>
            <Text as='title-lg' tag='h3'>
              Prior authorization forms
            </Text>
            <div className='flex w-full flex-col space-y-2'>
              {currentResource && (
                <ResourceItem
                  key={currentResource?.filename}
                  resourceIdx={currentResource.resourceId}
                  resource={currentResource}
                  selected
                />
              )}

              {data?.carriers[0].documents
                ?.filter((e, i) => i !== currentResourceId)
                .map((resource) => (
                  <ResourceItem
                    key={resource.filename}
                    resourceIdx={resource.resourceId}
                    resource={resource}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandInsuranceFormPage;
