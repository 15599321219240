import {Text, Chip, Icon} from '@prescriberpoint/ui';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {FC, useEffect, useMemo} from 'react';
import ResourceItem from './ResourceItem';
import {useUserAgentContext} from '@/context';
import {useCurrentSlug, useCurrentDrug} from '@/hooks';
import {useForms} from '@/queries';

export interface FormsProps {}

const FormViewer = dynamic(() => import('./FormViewer'));

const Forms: FC<FormsProps> = () => {
  const router = useRouter();
  const {resourceId, insuranceName, slug} = router.query;

  const {drugName} = useCurrentDrug();
  const {searchId} = useCurrentSlug();
  const {data: formsData} = useForms(searchId);
  const {isMobileOrTablet} = useUserAgentContext();

  const currentResourceId: number = Number(resourceId ?? -1);

  useEffect(() => {
    if (!isMobileOrTablet && !resourceId) {
      router.query.resourceId = '0';
      router.push(router);
    }
  }, [isMobileOrTablet, resourceId, router]);

  const chips = useMemo(() => {
    const _chips: string[] = formsData?.query?.split(' ') ?? [];
    if (insuranceName) {
      _chips.push(insuranceName as string);
    }
    return _chips;
  }, [formsData, insuranceName]);

  return (
    <div className='md:container md:mx-auto md:px-4'>
      <div className='mb-2 space-y-1 px-2 sm:mt-6 sm:px-0'>
        <Link href={`/therapies/${slug}`} passHref>
          <span className='flex items-center space-x-2 text-theme-primary no-underline hover:text-theme-dark'>
            <Icon name='LeftArrow' className='flex h-4 w-4' />
            <Text
              as='body-sm'
              weight='bold'
              variant='primary'
              className='hover:text-theme-dark hover:underline'>
              Back to {drugName}
            </Text>
          </span>
        </Link>

        <Text as='title-md' weight='semibold'>
          {formsData?.results?.[currentResourceId]?.title}
        </Text>
      </div>

      <div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
        {!isMobileOrTablet && formsData?.results?.[currentResourceId]?.url ? (
          <div className='col-span-3 md:col-span-2'>
            <FormViewer fileUrl={formsData?.results[currentResourceId].url} />
          </div>
        ) : null}
        <div className='order-first col-span-3 animate-fadeIn rounded-2xl bg-neutral-light p-4 md:order-last md:col-span-1'>
          <Text as='title-lg' tag='h3' className='mb-1' weight='semibold'>
            We searched hundreds of websites and came up with the following
            results.
          </Text>
          <Text variant='secondary'>Search keywords:</Text>

          <div className='mt-1 flex flex-wrap'>
            {chips.map((chip) => (
              <div key={chip}>
                <Chip className='m-1' label={chip} size='sm' />
              </div>
            ))}
            <Chip className='m-1' label='PDF Only' size='sm' disabled />
          </div>

          <div className='mt-6 flex w-full flex-col space-y-2 md:mt-11'>
            {formsData?.results?.map((resource, idx) => (
              <ResourceItem
                key={`${resource.title}-${resource.resourceId}`}
                resourceIdx={idx}
                resource={resource}
                selected={currentResourceId === idx}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forms;
