/* eslint-disable @next/next/no-img-element */
import {Text, Icon, Button, customEvent, Loader} from '@prescriberpoint/ui';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {FC, useMemo} from 'react';
import ActionButtons from './ActionButtons';
import {CEVENT_PA_RESOURCE_VIEW, DlvPaResourceView} from '@/constants';
import {useUserAgentContext} from '@/context';
import {useCurrentDrug} from '@/hooks';
import {IDocument} from '@/models';
import {formatISOtoString, getLastVerifiedDate} from '@/utils/date';
import {snakeToTitleCase, toSnakeCase} from '@/utils/string';

const MobileResourceItem = dynamic(() => import('./MobileResourceItem'), {
  loading: () => <Loader />,
});

export interface ResourceItemProps {
  resourceIdx: number;
  resource: IDocument;
  selected?: boolean;
}

const ResourceItem: FC<ResourceItemProps> = ({
  resourceIdx,
  resource,
  selected = false,
}) => {
  const {drugName} = useCurrentDrug();
  const router = useRouter();
  const {slug, payerName} = router.query;
  const lastUpdated = useMemo(
    () => formatISOtoString(getLastVerifiedDate(resource.lastUpdatedDate)),
    [resource.lastUpdatedDate],
  );

  const {isMobileOrTablet} = useUserAgentContext();

  const handleViewClick = () => {
    router.query.resourceId = `${resourceIdx}`;
    router.push(router);
    customEvent<DlvPaResourceView>(CEVENT_PA_RESOURCE_VIEW, {
      drugName,
      resourceName: resource.referenceName ?? '',
    });
  };

  return isMobileOrTablet ? (
    <MobileResourceItem
      resourceIdx={resourceIdx}
      resource={resource}
      selected={selected}
    />
  ) : (
    <div className='flex w-full space-x-2 rounded-md border border-solid border-neutral-light bg-white px-2 py-4'>
      <div className='flex h-10 w-10 items-center justify-center rounded-md bg-neutral-lighter p-1'>
        <div className='flex h-full w-full items-center justify-center bg-white'>
          <Icon name='Insurance' className='h-6 w-6' />
        </div>
      </div>

      <div className='flex w-full justify-between space-x-2'>
        <div className='flex flex-col'>
          <Text as='body-xs' weight='bold' casing='caps' variant='secondary'>
            Last verified: {lastUpdated}
          </Text>
          <Text
            weight='semibold'
            className='w-[216px] overflow-hidden text-ellipsis'>
            {resource.referenceName ?? snakeToTitleCase(resource.filename)}
          </Text>
        </div>
        <div className='flex w-[134px]'>
          {selected ? (
            <ActionButtons
              downLoadFileUrl={resource.filename}
              downloadFileName={resource.referenceName ?? resource.filename}
            />
          ) : (
            <Link
              className='w-full no-underline'
              prefetch
              href={`/therapies/${slug}/prior-authorization-forms/${payerName}?resourceId=${resourceIdx}`}
              passHref>
              <Button
                id={`view_${toSnakeCase(resource.filename)}`}
                stretched
                onClick={handleViewClick}>
                View
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResourceItem;
