import clsx, {ClassValue} from 'clsx';
import {FC, useMemo} from 'react';
import CtaSection from './CtaSection';
import HighlightSection from './HighlightSection';
import {HighlightSectionType} from './HighlightSection/models';
import {PdpHeader} from '@/components';
export interface IHeaderProps {
  samples?: boolean;
  coverage?: boolean;
  interactions?: boolean;
  financialAssistance?: boolean;
  priorAuthorization?: boolean;
  patientEducation?: boolean;
  className?: ClassValue;
}

const SECTIONS_NAME = {
  samples: 'samples',
  coverage: 'coverage',
  interactions: 'interactions',
  financialAssistance: 'financialAssistance',
  priorAuthorization: 'priorAuthorization',
  patientEducation: 'patientEducation',
};

const Header: FC<IHeaderProps> = ({
  samples = false,
  coverage = false,
  interactions = false,
  financialAssistance = false,
  priorAuthorization = false,
  patientEducation = false,
  className,
}) => {
  const sectionOrder = useMemo(
    () => [
      {name: SECTIONS_NAME.samples, status: samples},
      {name: SECTIONS_NAME.coverage, status: coverage},
      {name: SECTIONS_NAME.interactions, status: interactions},
      {name: SECTIONS_NAME.financialAssistance, status: financialAssistance},
      {name: SECTIONS_NAME.priorAuthorization, status: priorAuthorization},
      {name: SECTIONS_NAME.patientEducation, status: patientEducation},
    ],
    [
      coverage,
      financialAssistance,
      interactions,
      patientEducation,
      priorAuthorization,
      samples,
    ],
  );
  const highlightSection = useMemo((): HighlightSectionType | undefined => {
    const firstOpAvailable = sectionOrder.find((section) => section.status);
    return firstOpAvailable
      ? (firstOpAvailable.name as HighlightSectionType)
      : undefined;
  }, [sectionOrder]);

  const hasCtas = useMemo(
    () => sectionOrder.some((cta) => cta.status),
    [sectionOrder],
  );

  return (
    <div
      id='pdp_header'
      data-testid='pdp-header'
      className={clsx(
        'flex w-full flex-col items-center justify-center',
        className,
      )}>
      <div
        className={clsx(
          'flex w-full flex-col justify-center items-center rounded-b-2xl',
          'bg-green-100 px-6 pt-5',
          'md:px-10 md:pt-10',
          {'pb-6 md:bg-pdp-header md:pb-10': hasCtas || highlightSection},
          {'md:bg-pdp-header-without-cta': !hasCtas},
        )}>
        <PdpHeader />
        {highlightSection ? (
          <HighlightSection
            section={highlightSection}
            className='mx-auto mt-6 max-w-[1075px] md:mt-5'
          />
        ) : null}
      </div>
      {hasCtas ? (
        <CtaSection
          coverage={coverage && highlightSection !== SECTIONS_NAME.coverage}
          interactions={
            interactions && highlightSection !== SECTIONS_NAME.interactions
          }
          financialAssistance={
            financialAssistance &&
            highlightSection !== SECTIONS_NAME.financialAssistance
          }
          priorAuthorization={
            priorAuthorization &&
            highlightSection !== SECTIONS_NAME.priorAuthorization
          }
          patientEducation={
            patientEducation &&
            highlightSection !== SECTIONS_NAME.patientEducation
          }
          className='mx-auto max-w-[1155px] px-6 pt-6 md:px-10 md:pt-0'
        />
      ) : null}
    </div>
  );
};

export default Header;
