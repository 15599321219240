import {Text} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC} from 'react';
import DrugDescription from '../DrugDescription';
import {CompareDrugDropdown, type ICompareDrugOption} from '@/components';
import {useUserAgentContext} from '@/context';
import {useRouter} from '@/hooks/useRouter';

interface IDrugItemHeaderProps {
  drugName: string;
  genericName: string;
  className?: ClassValue;
}

export const DrugItemHeader: FC<IDrugItemHeaderProps> = ({
  drugName,
  genericName,
  className,
}) => {
  const {isMobileOrTablet} = useUserAgentContext();
  return (
    <div className={clsx('flex flex-col px-4 py-2.5 md:p-6', className)}>
      <Text
        as='headline-sm'
        size={isMobileOrTablet ? 'lg' : undefined}
        weight='bold'
        variant='white'>
        {drugName}®
      </Text>
      <Text as='body-md' weight='regular' variant='white'>
        ({genericName})
      </Text>
    </div>
  );
};

interface IDrugItemProps {
  drugName: string;
  genericName: string;
  description?: string;
  isPrimary?: boolean;
  options: ICompareDrugOption[];
}

const DrugItem: FC<IDrugItemProps> = ({
  drugName,
  genericName,
  description,
  isPrimary,
  options,
}) => {
  const router = useRouter();
  const {indication} = router.query;

  return (
    <div className='w-full md:w-1/2' data-testid={`${drugName}_dropdown`}>
      <DrugItemHeader
        drugName={drugName}
        genericName={genericName}
        className={
          isPrimary
            ? 'rounded-tl-xl bg-theme-primary'
            : 'rounded-tr-xl border-0 border-l border-solid border-white bg-theme-dark-alt'
        }
      />
      <div
        className={clsx([
          'bg-neutral-secondary-alt px-4 py-2.5 md:px-6',
          !isPrimary ? 'border-0 border-l border-solid border-white' : null,
        ])}>
        <CompareDrugDropdown
          category={(indication as string).split('-').join(' ')}
          selectedLabel={drugName}
          options={options}
        />
      </div>
      <DrugDescription
        className={
          !isPrimary
            ? 'border-0 border-l border-solid border-neutral-lighter'
            : null
        }
        text={description}
      />
    </div>
  );
};

export default DrugItem;
