'use client';
import {Text} from '@prescriberpoint/ui';
import {FC, useEffect, useMemo} from 'react';
import CompareDrugsTable, {ITableData} from './CompareDrugsTable';
import CompareLinksList from './CompareLinksList';
import {
  CEVENT_COMPARE_DRUG_LABEL_CHANGE,
  DlvCompareDrugLabelChange,
} from '@/constants';
import {useUserAgentContext} from '@/context';
import {useCompareIndicationDrugOptions, useIndicationDrugs} from '@/hooks';
import {useRouter} from '@/hooks/useRouter';
import {customEvent} from '@/utils/gtm';

const TALTZ_GROUP = ['taltz-ac96658', 'emgality-33a147b', 'verzenio-be4bc0d'];

export interface ICompareDrugsPageProps {}

const CompareDrugsPage: FC<ICompareDrugsPageProps> = () => {
  const {drug1, drug2} = useIndicationDrugs();
  const {isMobileOrTablet} = useUserAgentContext();
  const router = useRouter();
  const {indication} = router.query;

  const {options1, options2} = useCompareIndicationDrugOptions(
    indication as string,
    drug1.slug,
    drug2.slug,
  );

  useEffect(() => {
    let principalSlug = drug1.slug;
    let principalDrugName = drug1.drugName;

    // traffic to emgality and verzenio is counted as taltz
    if (TALTZ_GROUP.includes(drug1.slug)) {
      principalSlug = TALTZ_GROUP[0];
      principalDrugName = 'Taltz';
    }

    customEvent<DlvCompareDrugLabelChange>(CEVENT_COMPARE_DRUG_LABEL_CHANGE, {
      principalSlug,
      principalDrugName,
      secondarySlug: drug2.slug,
      secondaryDrugName: drug2.drugName,
    });
  }, [drug1, drug2]);

  const data = useMemo<ITableData>(
    () => ({
      indication: indication as string,
      slugs: [drug1.slug, drug2.slug],
      headers: [
        {
          drugName: drug1.drugName,
          genericName: drug1.genericName,
        },
        {
          drugName: drug2.drugName,
          genericName: drug2.genericName,
        },
      ],
      dropdownOptions: [options1, options2],
      descriptions: [drug1.description, drug2.description],
      subHeaders: [
        {
          title: 'Dosage & Administration',
          expandableRows: [
            {
              content: 'Administration',
              subRows: [
                {
                  text: drug1.administration,
                  pdpLinkTag: 'drug_label',
                },
                {
                  text: drug2.administration,
                  pdpLinkTag: 'drug_label',
                },
              ],
            },
            {
              content: 'Dosing',
              subRows: [
                {
                  text: drug1.dosing,
                  pdpLinkTag: 'drug_label',
                },
                {
                  text: drug2.dosing,
                  pdpLinkTag: 'drug_label',
                },
              ],
            },
            {
              content: 'Latin Shorthand',
              subRows: [
                {
                  text: drug1.latinShorthand,
                  pdpLinkTag: 'drug_label',
                },
                {
                  text: drug2.latinShorthand,
                  pdpLinkTag: 'drug_label',
                },
              ],
            },
          ],
        },
        {
          title: 'Financial Assistance',
          expandableRows: [
            {
              content: 'Out-Of-Pocket Costs With Copay Card',
              subRows: [
                {
                  text: drug1.outOfPocketCostWithCopayCard,
                  pdpLinkTag: 'financial_assistance',
                },
                {
                  text: drug2.outOfPocketCostWithCopayCard,
                  pdpLinkTag: 'financial_assistance',
                },
              ],
            },
            {
              content: 'Annual Cap',
              subRows: [
                {
                  text: drug1.anualCap,
                  pdpLinkTag: 'financial_assistance',
                },
                {
                  text: drug2.anualCap,
                  pdpLinkTag: 'financial_assistance',
                },
              ],
            },
            {
              content: 'Assistance Expiration',
              subRows: [
                {
                  text: drug1.assitanceExpiration,
                  pdpLinkTag: 'financial_assistance',
                },
                {
                  text: drug2.assitanceExpiration,
                  pdpLinkTag: 'financial_assistance',
                },
              ],
            },
            {
              content: 'Generics',
              subRows: [
                {
                  text: drug1.generics,
                },
                {
                  text: drug2.generics,
                },
              ],
            },
          ],
        },
        {
          title: 'Physician Advisory',
          expandableRows: [
            {
              content: 'Adverse Reactions',
              subRows: [
                {
                  text: drug1.adverseReactions,
                  pdpLinkTag: 'drug_label',
                },
                {
                  text: drug2.adverseReactions,
                  pdpLinkTag: 'drug_label',
                },
              ],
            },
            {
              content: 'Mechanism of Actions (MoA)',
              subRows: [
                {
                  text: drug1.moas,
                  pdpLinkTag: 'drug_label',
                },
                {
                  text: drug2.moas,
                  pdpLinkTag: 'drug_label',
                },
              ],
            },
            {
              content: 'Special Populations',
              subRows: [
                {
                  html: drug1.specificPopulations,
                },
                {
                  html: drug2.specificPopulations,
                },
              ],
            },
          ],
        },
      ],
    }),
    [drug1, drug2, indication, options1, options2],
  );

  return (
    <div className='mx-auto max-w-[1265px] flex-col bg-white px-5 pt-14'>
      <div className='flex flex-col space-y-5'>
        <Text size='md' weight='regular' className='!text-neutral-darker'>
          Compare drug alternatives
        </Text>
        <Text
          tag='h1'
          as={isMobileOrTablet ? 'headline-sm' : 'headline-lg'}
          weight='bold'>
          {drug1?.drugName}® Alternatives
        </Text>
      </div>

      <div className='relative space-y-12 md:mb-4 md:flex md:space-x-5 md:space-y-0'>
        <div className='overflow-x-scroll bg-neutral-lighter-alt px-0 pt-6 md:w-[70%] md:bg-white'>
          <div className='min-w-[580px] max-w-[960px] sm:min-w-[706px] md:max-w-[706px]'>
            <CompareDrugsTable data={data} />
          </div>
        </div>
        <div className='md:sticky md:top-10 md:w-[30%] md:self-start md:pt-6'>
          <div className='w-full bg-neutral-lighter md:static md:bg-transparent'>
            <CompareLinksList
              drug1={{
                slug: drug1?.slug,
                setId: drug1?.setId,
                drugName: drug1?.drugName,
              }}
              drug2={{
                slug: drug2?.slug,
                setId: drug2?.setId,
                drugName: drug2?.drugName,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareDrugsPage;
